export const getPriceBySku = (sku, products, bundles) => {
  let match = null
  match = bundles?.find(
    bundle => bundle.masterSku === sku
  )
  if (!match){
  products.find(
    product => (match = product.variants.find(variant => variant.sku === sku)),
  )
  }
  return match ? match.price : null
}

export const getProductBySku = (sku, products, bundles) => {
  const bundle = bundles?.find(
    bundle => bundle.masterSku === sku
  )
  const product = products.find(product =>
    product.variants.find(variant => variant.sku === sku),
  )
  return bundle || product
}

export const getProductBySlug = (slug, products) => {
  const product = products.find(product => product.slug === slug)
  return product
}

export const getVariantBySku = (sku, products, bundles) => {
  let match = null
  match = bundles?.find(
    bundle => bundle.masterSku === sku
  )
  if (!match){
    products.find(
      product => (match = product.variants.find(variant => variant.sku === sku)),
    )
  }

  return match
}
